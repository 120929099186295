import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Works`}</h1>
    <h2>Portfolio Site</h2>
自分のポートフォリオサイトです。
TypeScript + Gatsby.js でサイトの全体を書いていて、ヒーローイメージは react-three-fiber で WebGL から Shader をいじっています。
Netlify CMS を使って管理画面からブログ記事を作成・投稿できます。
さらに MDX(JSX in Markdown)という闇技術で、ブログ記事中でも React コンポーネントを import して使えたりもします。全部載せ感。

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      