import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hello, I'm hpp`}</h1>
    <p>{`名古屋大学の情報学部コンピュータ科学に所属する 3 年生です。`}</p>
    <p>{`普段は CS 系のお勉強をしています(成績は良さめです)。`}<br />{`
「CS 系っていってもいろいろあるやろ」と思った方のために掘り下げると、アルゴリズムとか離散数学とか代数学とか機械学習とか、最適化(線型計画法など)とか自然言語処理とかです。多分研究室配属後は自然言語処理の研究をすると思います。`}</p>
    <br />
    <p>{`趣味で色々なものを作ったり遊んだりしています。現状フロントエンドの方が得意ですが、ロジックを考えるのが好きなのでサーバサイドのプログラミングもとても好きです。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      