import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Events`}</h1>
    <p>{`様々なイベントへの参加録です。`}</p>
    <ul>
      <li parentName="ul">{`学生 LT in 名古屋工学学院専門学校 LT`}</li>
      <li parentName="ul">{`SILT2019`}</li>
      <li parentName="ul">{`teamLab Summer Intern 2019 パッケージチーム`}</li>
      <li parentName="ul">{`ISUCON9 予選`}</li>
      <li parentName="ul">{`ALife Game Jam 2019`}</li>
      <li parentName="ul">{`ICPC2019 国内予選`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      