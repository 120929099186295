import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`What is this?`}</h1>
    <p>{`hpp(`}<a href="https://twitter.com/hpp_ricecake" style={{
        color: "#fff"
      }}>{`@hpp_ricecake`}</a>{`)のポートフォリオサイトです。`}</p>
    <p>{`テンプレートや UI フレームワークは使わずに、全て自作しました(デザインと背景も！)(右上の 🍔 メニューも自作です、頑張りました)。`}</p>
    <p>{`以下使用技術を思いつくだけ書いてみます。`}</p>
    <br />
    <ul>
      <li parentName="ul">{`React.js`}</li>
      <li parentName="ul">{`Gatsby.js (Blazing fast🔥 static site generator)`}</li>
      <li parentName="ul">{`TypeScript`}</li>
      <li parentName="ul">{`GraphQL`}</li>
      <li parentName="ul">{`MDX (JSX in Markdown)`}</li>
      <li parentName="ul">{`PWA (Progressive web application)`}</li>
      <li parentName="ul">{`Netlify (static site hosting & CI/CD)`}</li>
      <li parentName="ul">{`Netlify CMS`}</li>
      <li parentName="ul">{`WebGL`}</li>
      <li parentName="ul">{`Three.js (react-three-fiber)`}</li>
      <li parentName="ul">{`react-spring (react's animation library)`}</li>
    </ul>
    <br />
    <p>{`PWA なので、ローカルマシンにサイトを落とせます。適度に更新していく予定なので興味があればぜひ！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      