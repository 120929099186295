import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Skills`}</h1>
    <div>
大学入学して初めてプログラミングに触れたので、できることがとても多いかというとそうではありません。でも技術自体が好きなタイプなので新しいことを学ぶのは特に抵抗が無いです。なのでこれからも色々遊んでいきたいなと思っています。
割と触ったことのあるものが色々あるので、詳細は別ページにまとめています。
      <p>{`よかったら下のボタンを触ってみてください 👇`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      